<template>
  <div>
    <ion-card v-if="post" class="blab-card p-2 m-0 clickable-item" style="position: relative" @click="openBlabModal">
      <div class="d-flex flex-wrap" style="position: absolute; z-index: 2; left: 8px; width: 66%">
        <ion-badge class="badge" :style="`color: ${getBadgeColor}; border: 2px solid ${getBadgeColor}`">
          {{ post.category === 'art_wip' ? 'Art' : blabCategories[post.category].name }}
        </ion-badge>
        <div v-if="post.category === 'art_wip'">
          <ion-badge class="badge" :style="`color: ${getBadgeColor}; border: 2px solid ${getBadgeColor}`">
            WIP
          </ion-badge>
        </div>
        <MatureTag class="mt-1 ml-1" v-if="isNsfw" />
      </div>
      <div
        v-if="isOwner"
        class="clickable drop-down d-flex align-items-center justify-content-center"
        :class="{ indexer: extractImages.length }"
        @click.stop.prevent="openDropDown"
      >
        <i class="ti-more-alt" />
      </div>

      <div v-if="extractImages.length" style="position: absolute; z-index: 2; right: 9px">
        <ion-badge style="font-size: 13px">
          <ion-icon class="mr-1" style="color: white" :icon="copyOutline"></ion-icon>
          {{ extractImages.length }}
        </ion-badge>
      </div>

      <div :class="extractImages.length ? '' : 'mt-4'">
        <div
          v-if="extractImages.length"
          class="mb-2"
          :style="postImageSize"
          style="margin: 0 auto; display: flex; align-items: center; justify-content: center"
          @click.stop
        >
          <img
            v-image
            style="width: 100%; height: 100%; border-radius: 12px"
            :src="resizeUpload(extractImages[0], '380h')"
            @click.prevent="openBlabModal"
          />
        </div>

        <div :class="{ 'mt-5': isNsfw && !extractImages.length }" class="flex-grow-1">
          <div class="title text-black d-flex align-items-center justify-content-start">
            <span v-if="post.privacy === 'only_me'" class="mr-1"> 🔒 </span>
            {{ post.title }}
          </div>
          <p>
            {{ truncateText(stripHtmlTags(post.description), 150) }}
          </p>
        </div>
        <div class="mt-2 ml-n1 d-flex align-items-center justify-content-start">
          <div class="actions d-flex">
            <a href="#" @click.prevent>
              <Reaction
                type="post"
                :reactions="mutatedReactionsData?.reaction_counts"
                :user-reaction="mutatedReactionsData?.user_reaction"
                :left-indent-popup="!indentReactionRight"
                :right-indent-popup="indentReactionRight"
                :showBox="get(post, 'showBox')"
                :progressId="get(post, 'progressId')"
                @changed="(reaction) => reactionChanged(reaction.key, post, reaction.isInstant)"
                @updated="emits('updated')"
                @delete="deleteCurrGiftBox"
                @click.stop
              >
                <ion-button
                  color="transparent"
                  class="inline-button clickable"
                  :class="
                    !!mutatedReactionsData?.user_reaction && mutatedReactionsData?.user_reaction.reaction
                      ? 'text-secondary'
                      : 'text-light'
                  "
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <i
                      :color="
                        !!mutatedReactionsData?.user_reaction && mutatedReactionsData?.user_reaction.reaction
                          ? 'secondary'
                          : 'primary'
                      "
                      class="ti-thumb-up mr-1 reaction-icon"
                    />
                    <span
                      class="reaction-count"
                      :class="
                        !!mutatedReactionsData?.user_reaction && mutatedReactionsData?.user_reaction.reaction
                          ? 'text-secondary'
                          : 'text-primary'
                      "
                      >{{ mutatedReactionsData?.reaction_counts?.total_count || 0 }}</span
                    >
                  </div>
                </ion-button>
              </Reaction>
            </a>
          </div>

          <div v-if="blabRepliesCount" class="d-flex align-items-center justify-content-center">
            <ion-icon style="" class="d-inline-block comment-icon ml-2 mr-1" :icon="chatboxEllipsesOutline" />{{
              blabRepliesCount
            }}
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-start ml-auto mt-1" @click.stop>
          <CommenterImage
            size="sm"
            :src="
              post.character
                ? resizeUpload(get(post, 'character.info.cropProfilePicture'))
                : resizeUpload(get(post, 'user.profile_picture_url'))
            "
            :ring="
              post.character
                ? get(post, 'character.profile_ring.image')
                : get(post, 'user.customize_profile.profile_ring.image')
            "
            :ringWidth="30"
            :top="-2"
            :type="post.character ? 'character' : 'user'"
            class="mr-1"
          />
          <router-link
            v-if="post.character"
            :to="{
              name: 'character-profile-new',
              params: { slug: get(post.character, 'slug') },
            }"
            >{{ truncateText(get(post.character, 'info.name'), 15) }}
          </router-link>
          <div v-else class="d-flex flex-wrap align-items-center">
            <router-link
              :class="{
                'text-color-username': usernameColor && !usernameColor.startsWith('#'),
                'bold-username': usernameColor,
              }"
              :style="
                !usernameColor
                  ? ''
                  : usernameColor.startsWith('#')
                  ? `color: ${usernameColor} !important`
                  : `background-image: ${usernameColor} !important`
              "
              :to="{
                name: 'profile',
                params: { username: get(post, 'user.username') },
              }"
              class="mr-1"
              >@{{ truncateText(get(post, 'user.username'), 15) }}
            </router-link>

            <ProfileBadge :badges="badges" :show-small-badge="true" />
          </div>
        </div>
      </div>
    </ion-card>
    <blab-details-modal
      :is-open="openBlabAsModal"
      :blabid="post.id"
      @dismiss-modal="closeBlabAsModal"
      @edit="openEditBlab"
      @profile="openProfile"
      @details="openDetailsPage"
      @afterReaction="(reactionResp) => reaction(reactionResp)"
    />
  </div>
</template>

<script setup lang="ts">
import { chatboxEllipsesOutline, copyOutline } from 'ionicons/icons';
import CommenterImage from '@/shared/components/storage/CommenterImage.vue';
import { stripHtmlTags } from '@/shared/utils/string';
import constants from '@/shared/statics/constants';
import store from '@/shared/store';
import BlabDetailsModal from '@/shared/modals/BlabDetailsModal.vue';
import MatureTag from '@/shared/components/MatureTag.vue';
import CardPopover from '@/shared/components/storage/popovers/CardPopover.vue';
import { authStore } from '@/shared/pinia-store/auth';
import { popovers } from '@/shared/native/popovers';
import ProfileBadge from '@/shared/pages/profile/components/ProfileBadge.vue';
import { resizeUpload } from '@/shared/utils/upload';
import { truncateText } from '@/shared/utils/string';
import Reaction from '@/shared/components/Reaction/index.vue';

const props = defineProps({
  post: {
    type: Object,
    default: () => {},
    required: true,
  },

  blabRepliesCount: {
    type: Number,
    default: 0,
  },
  indentReactionRight: {
    type: Boolean,
    default: false,
  },
  reactedPosts: {
    type: Object,
    default: null,
  },
  lazyUserReaction: {
    type: Boolean,
    default: false,
  },
  containerHeight: {
    type: Number,
    default: 0,
  },
});

const openBlabAsModal = ref(false);
const post = toRef(props, 'post');
const { user } = authStore();
const emits = defineEmits(['fetch', 'updated', 'delete']);
const reactedPosts = toRef(props, 'reactedPosts');
const router = useRouter();
const containerHeight = toRef(props, 'containerHeight');
const reactionCount = ref(post.value.reaction_counts);
const lazyUserReaction = toRef(props, 'lazyUserReaction');
const mutatedReactionsData = lazyUserReaction.value
  ? ref({
      reaction_counts: reactionCount.value,
      user_reaction: reactedPosts.value || null,
    })
  : ref(pick(post.value, ['reaction_counts', 'user_reaction']));

watch([reactionCount, reactedPosts], () => {
  if (lazyUserReaction.value) {
    mutatedReactionsData.value = {
      reaction_counts: reactionCount.value,
      user_reaction: reactedPosts.value,
    };
  }
});

const postImageSize = computed(() => {
  return containerHeight.value ? { height: `${containerHeight.value}px` } : { height: 'auto' };
});
const deleteCurrGiftBox = () => {
  emits('delete', post.value.id);
};
const badges = computed(() => {
  return get(post.value?.user.customize_profile, 'badges') || [];
});
const blabCategories = keyBy(constants.blabCategories, 'value');
const getBadgeColor = computed(() => {
  return blabCategories[post.value.category].color;
});
const id = computed(() => post?.value?.id);

const isNsfw = computed(() => post.value.is_nsfw);

const usernameColor = computed(() => {
  return post.value?.user.customize_profile?.username_color || '';
});

const extractImages = computed(() => {
  let imgSrc;
  const imgUrls = [];
  const rex = /<img[^>]+src="(https:\/\/[^">]+)"/g;

  while ((imgSrc = rex.exec(post.value.description))) {
    imgUrls.push(imgSrc[1]);
  }

  return imgUrls;
});

const isOwner = computed(() => {
  try {
    return post.value.user!.username === user.value.username;
  } catch (error) {
    return false;
  }
});

const openDropDown = async (ev: CustomEvent) => {
  await popovers.open(ev, CardPopover, { title: 'Post', id: id.value, value: 'post' });
};
const reactionChanged = async (reaction: string, post: any, isInstant = false) => {
  const reactionResp = await store.dispatch('BlabsModule/react', {
    reaction,
    blab: {
      ...post,
      reaction_counts: mutatedReactionsData.value.reaction_counts,
      user_reaction: mutatedReactionsData.value.user_reaction,
    },
    isInstant,
  });

  mutatedReactionsData.value = {
    reaction_counts: reactionResp.updatedReactionsData.reaction_counts,
    user_reaction: reactionResp.updatedReactionsData.user_reaction,
  };
};

const reaction = (reactResp: any) => {
  mutatedReactionsData.value = {
    reaction_counts: reactResp.updatedReactionsData.reaction_counts,
    user_reaction: reactResp.updatedReactionsData.user_reaction,
  };
};

const openBlabModal = () => {
  openBlabAsModal.value = true;
};

const closeBlabAsModal = () => {
  openBlabAsModal.value = false;
  emits('fetch');
  //fetchBlabReplies();
};

const openEditBlab = (edit: boolean, id: string) => {
  if (edit) {
    openBlabAsModal.value = false;
    router.push({ name: 'edit-post', params: { id } });
  }
};

const openProfile = (openprofile: boolean) => {
  if (openprofile) {
    openBlabAsModal.value = false;
  }
};

const openDetailsPage = () => {
  openBlabAsModal.value = false;
  router.push({ name: 'post-details', params: { id: post.value.id } });
};
</script>

<style lang="sass" scoped>
.indexer
  right: 60px !important
.drop-down
  position: absolute
  z-index: 2
  right: 8px
  width: 20px
  height: 20px
  color: #FFF
  background: #0A0928E5
  font-size: 12px
  border-radius: 6px
  top:9px

.title
  font-size: 18px
  font-weight: bold
  overflow-wrap: anywhere
@media(max-width: 419px)
  .title.title
    font-size: 16px

.reply
  font-size: 14px

.icon
  font-size: 14px
  font-weight: bold

.reaction-icon
  font-size: 16px
  font-weight: bold

.visuals
  width: 100%
  height: auto

ion-badge

  display: flex

  justify-content: center
  align-items: center
  background: #09183c

  min-width: 10px
  top: 0px
  border-radius: 12px

  padding-top: 4px
  padding-bottom: 4px
  padding-left: 12px
  padding-right: 12px
  font-size: 10px

.comment-icon
  font-size: 17px
  color: grey
  margin-top: 3px

.actions
  // max-width: 200px
.reaction-count
  font-size: 14px
  font-weight: bold

.blab-card

  width: 100%

  .username-text
    word-break: break-all

  .blab-title
    font-size: 16px
    font-weight: bold
.img
  min-width: 25px
  width: 25px !important
  height: 25px !important
  border-radius: 30px
  border: solid gray 0.1px
  object-fit: cover

.blabs-list::-webkit-scrollbar
  display: none
.blabs-list
  -ms-overflow-style: none
  scrollbar-width: none
</style>
